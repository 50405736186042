<template>
  <div>
    <v-progress-circular
      class="loader"
      size="70"
      color="black"
      v-if="loader"
      indeterminate
    ></v-progress-circular>
    <v-row class="ma-0 pa-4 align-center">
      <v-col cols="2" class="pa-0"
        ><h3 width="100" height="24" class=".text-lg-h6">Master</h3></v-col
      >
      <v-col class="pa-0" cols="1"></v-col>
      <v-col cols="6" class="pa-0">
        <v-text-field
          v-model="search"
          single-line
          dense
          outlined
          prepend-inner-icon="mdi-magnify"
          placeholder="Search"
        ></v-text-field>
      </v-col>
      <v-col class="pa-0" cols="1"></v-col>

      <v-col cols="2" class="pa-0 d-flex justify-end"
        ><v-btn
          depressed
          color="align-center justify-center text-none pa-0 bgr_clr_btn_save "
          min-width="155px"
        >
          Create New
        </v-btn></v-col
      >
    </v-row>

    <v-data-table
      :headers="headers"
      :items="collectionLists"
      :items-per-page="5"
      v-model="search"
      :search="search"
      class="elevation-1 mx-5 subtitle"
      id="table"
    >
      <template slot="this.customerList" slot-scope="">
        <tr>
          <th
            v-for="(item, index) in this.loanDetails"
            :key="index"
            class="tableRowHeight"
          ></th>
        </tr>
      </template>
      <template v-slot:item="props">
        <tr @click="loanRecords(props.index)">
          <td>{{ props.index + 1 }}</td>
          <td>{{ props.item.customer_name }}</td>
          <td>{{ props.item.mobile_number }}</td>
          <td>{{ props.item.Designation }}</td>
          <td>{{ props.item.user_group }}</td>
          <td>{{ props.item.branch }}</td>
           <td class="pa-0">  <span class="pa-0"   v-bind:class="{
                    inprogress: props.item.status == 'In-Progress',
                    success: props.item.status == 'Completed',
                  }"></span>
              <!-- <img :src="props.item.status == 'Completed'? completed : inprogress" />  -->
            <span class="ml-2 pa-0">
             {{ props.item.status }} 
            </span>
              </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Vue from "vue";
import httpservice from "../js/httpservice";
import EventBus from "../main";
import { mapState } from "vuex";
export default {
  data() {
    return {
      search: "",
      loanDetails: [],
      headers: [
        {
          text: "S.NO",
          align: "start",
          sortable: false,
          value: "customer_name",
          class: "",
        },
        { text: "Name", value: "customer_name ", sortable: false },
        { text: "Mobile No", value: "mobile_number", sortable: false },
        { text: "Designation", value: "Designation", sortable: false },
        { text: "User Group", value: "user_group", sortable: false },
        { text: "Branch", value: "branch", sortable: false },
        { text: "Status", value: "status", sortable: false },
      ],
      collectionLists: [
        {
          user_group: "NA",
          customer_name: "DINESH",
          mobile_number: 9978534321,
          Designation: "NA",
          branch: "CHENNAI",
          status: "Completed",
        },
        {
          user_group: "NA",
          customer_name: "DINESH",
          mobile_number: 9978534321,
          Designation: "NA",
          branch: "CHENNAI",
          status: "In-Progress",
        },
        {
          user_group: "NA",
          customer_name: "DINESH",
          mobile_number: 9978534321,
          Designation: "NA",
          branch: "CHENNAI",
          status: "In-Progress",
        },
        {
          user_group: "NA",
          customer_name: "DINESH",
          mobile_number: 9978534321,
          Designation: "NA",
          branch: "CHENNAI",
          status: "In-Progress",
        },
        {
          user_group: "NA",
          customer_name: "DINESH",
          mobile_number: 9978534321,
          Designation: "NA",
          branch: "CHENNAI",
          status: "Completed",
        },
        {
          user_group: "NA",
          customer_name: "DINESH",
          mobile_number: 9978534321,
          Designation: "NA",
          branch: "CHENNAI",
          status: "In-Progress",
        },
        {
          user_group: "NA",
          customer_name: "DINESH",
          mobile_number: 9978534321,
          Designation: "NA",
          branch: "CHENNAI",
          status: "Completed",
        },
        {
          user_group: "NA",
          customer_name: "DINESH",
          mobile_number: 9978534321,
          Designation: "NA",
          branch: "CHENNAI",
          status: "Completed",
        },
        {
          user_group: "NA",
          customer_name: "DINESH",
          mobile_number: 9978534321,
          Designation: "NA",
          branch: "CHENNAI",
          status: "Completed",
        },
        {
          user_group: "NA",
          customer_name: "DINESH",
          mobile_number: 9978534321,
          Designation: "NA",
          branch: "CHENNAI",
          status: "Completed",
        },
      ],
      loader: false,
    };
  },
  computed: {
    ...mapState(["loading"]),
  },
  methods: {
    loanRecords(val) {
      console.log(this.loanDetails[val]);

      EventBus.$emit("loanRecods", this.loanDetails[val]);
      this.$store.state.message = this.loanDetails[val];
      Vue.prototype.message = this.loanDetails[val];
    },

    /** /
     * Method to call get LoanDetails
     * @author Saranraj
     * @since 22/11/2021
     * @return na
     */

    getLoanRecords() {
      this.loader = true;
      let jsonData = {
        loan_id: "0",
      };
      httpservice
        .getLoanData(jsonData)
        .then((response) => {
          console.log(response);
          if (response.status == 200) {
            if (response.data["status"] > 0) {
              this.loanDetails = response.data["result"];
              console.log(this.loanDetails);
            } else {
              console.log("Failed");
            }
          }
        })
        .finally(() => {
          this.loader = false;
        });
    },
  },
  mounted() {
    this.getLoanRecords();
    if (
      localStorage.getItem("Loanid") != undefined ||
      localStorage.getItem("Loanid") != null
    ) {
      this.loanid = JSON.parse(localStorage.getItem("Loanid"));
      console.log(this.loanid);
    }
  },
};
</script> 

<style scoped>
.tableRowHeight {
  height: 56px !important;
}
::v-deep .v-text-field__details {
  display: none !important;
}
::v-deep .v-input__slot {
  margin: 0 !important;
}
.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  font-size: inherit;
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 40px;
}
.container {
  max-width: 1366px;
}
.fontfamily {
  font-family: "Lato";
}
.v-data-table ::v-deep th {
  font-size: 14px !important;
  color: black !important;
}
::v-deep .v-data-table__empty-wrapper {
  display: none !important;
}
</style>